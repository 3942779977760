/* global googletag */

import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useBannerDefault = (sponsor, bannerSizes) => {
  const location = useLocation();

  const initializeGoogletag = () => {
    window.googletag = window.googletag || { cmd: [] };

    window.googletag.cmd.push(function () {
      const slot = googletag
        .pubads()
        .getSlots()
        .find(slot => slot.getSlotElementId() === sponsor.divName);

      if (slot) {
        googletag.destroySlots([slot]);
      }

      googletag
        .defineSlot(
          '/23107569379/v3-ahshow-default',
          bannerSizes,
          sponsor.divName,
        )
        .setTargeting('position', [sponsor.param])
        .addService(googletag.pubads());

      googletag.pubads().enableSingleRequest();
      googletag
        .pubads()
        .setTargeting('channel', ['ahshow'])
        .setTargeting('page-type', [sponsor.page]);

      googletag.enableServices();
      googletag.display(sponsor.divName);
    });
  };

  useEffect(() => {
    const loadScript = callback => {
      if (!window.googletag || !window.googletag.cmd) {
        const script = document.createElement('script');
        script.async = true;
        script.onload = () => {
          window.googletag = window.googletag || { cmd: [] };
          callback();
        };
        document.head.appendChild(script);
      } else {
        callback();
      }
    };

    loadScript(initializeGoogletag);

    return () => {
      if (window.googletag && window.googletag.destroySlots) {
        const slot = googletag
          .pubads()
          .getSlots()
          .find(slot => slot.getSlotElementId() === sponsor.divName);
        if (slot) {
          googletag.destroySlots([slot]);
        }
      }
    };
  }, [location.pathname]);

  useEffect(() => {
    const refreshAd = () => {
      if (window.googletag && window.googletag.destroySlots) {
        window.googletag.cmd.push(() => {
          const slot = googletag
            .pubads()
            .getSlots()
            .find(slot => slot.getSlotElementId() === sponsor.divName);

          if (slot) {
            googletag.pubads().refresh([slot]);
          }
        });
      }
    };

    refreshAd();
  }, [location.key]);
};
