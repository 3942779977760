import styled from 'styled-components';

export const ModalDiv = styled.div`
  width: 892px;
  background-color: #121212;
  border-radius: 24px;
  position: fixed;
  top: 15rem;
  left: calc(35vw - 200px);
  flex-direction: column;
  z-index: 99999;

  @media (max-width: 912px) {
    width: 90%;
    left: calc(35vw - 30%);
    top: calc(15vh);

    textarea {
      width: 90%;
    }
  }

  @media (max-width: 700px) {
    left: 1.5rem;
  }
`;

export const CloseBtn = styled.button`
  width: 30px;
  height: 35px;
  font-size: 26px;
  font-style: normal;
  color: #ff00b7;
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  margin: 10px;
  border: none;
  cursor: pointer;
`;

export const TittleDiv = styled.div`
  text-align: center;
`;

export const Button = styled.button`
  margin: 10px auto;
  width: 209px;
  height: 44px;
  border-radius: 14px;
  background-color: #ff00b7;
  border: none;
  color: #ffffff;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 5%;
  cursor: pointer;
`;
