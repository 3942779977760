import { useState } from 'react';
import { toastErr, toastSuc } from '../../config/toastComponent';
import { useOpenCreateGalleryModal } from '../../contexts/CreateGalleryModal/useOpenCreateGalleryModal';
import { useUserInterests } from '../../contexts/UserInterests/useUserInterests';
import { createMyGallery } from '../../services/Requests';
import * as C from './style';

const initialState = {
  name: '',
  description: '',
};

export const CreateGalleryModal = () => {
  const [createdGallery, setCreatedGallery] = useState(false);
  //const [isPublic, setIsPublic] = useState(false);
  const [fields, setFields] = useState(initialState);

  const { galleryRefetch, dataGalleries } = useUserInterests();
  const modal = useOpenCreateGalleryModal();

  /* const toggleChecked = () => {
    setIsPublic(!isPublic);
  }; */

  const handleFieldsChange = ev => {
    setFields({
      ...fields,
      [ev.target.name]: ev.target.value,
    });
  };

  const checkIfExists = () => {
    return dataGalleries?.items?.some(gallery => {
      return gallery?.name?.toLowerCase() === fields.name.toLowerCase();
    });
  };

  const handleFormSubmit = async ev => {
    ev.preventDefault();
    if (fields.name.trim().length < 3) {
      return toastErr('Favor inserir no mínimo 3 letras no nome!');
    }
    if (fields.description.trim().length < 5) {
      return toastErr('Favor inserir no mínimo 5 letras na descrição!');
    }

    const result = checkIfExists();
    if (result) return toastErr('Esse título já existe em sua lista!');

    await createMyGallery(fields.name, fields.description, false)
      .then(() => {
        galleryRefetch();
        setCreatedGallery(true);
        setFields(initialState);
        modal.setOpenGalleryModal(false);
        toastSuc(`Lista ${fields.name} criada!`);
        return;
      })
      .catch(() => {
        return toastErr('Tente novamente!');
      });
  };

  if (!createdGallery) {
    return (
      <C.ModalDiv style={{ display: modal.openGalleryModal ? 'flex' : 'none' }}>
        <C.CloseBtn onClick={() => modal.setOpenGalleryModal(false)}>
          X
        </C.CloseBtn>
        <h2 style={{ margin: '2.5rem auto 2.5rem auto', textAlign: 'center' }}>
          Criar lista
        </h2>
        <form>
          <C.InputsDiv>
            <input
              id='name'
              name='name'
              type='text'
              value={fields.name}
              onChange={handleFieldsChange}
              placeholder='Nome da lista'
              minLength={3}
              maxLength={50}
              required
            />
            <C.SpanArea>
              <span
                id='SpanNameError'
                style={{ color: '#ff00b7', opacity: '0.6' }}
              >
                {fields.name.length <= 3 ? <>Mínimo de 3 Caracteres</> : ''}{' '}
              </span>
              <span> {fields.name.length}/50 </span>
            </C.SpanArea>
            <textarea
              name='description'
              id='description'
              placeholder='Descrição'
              value={fields.description}
              onChange={handleFieldsChange}
              minLength={5}
              maxLength={250}
              required
            ></textarea>
            <C.SpanArea>
              <span
                id='SpanTextAreaError'
                style={{ color: '#ff00b7', opacity: '0.6' }}
              >
                {fields.description.length <= 5 ? (
                  <>Mínimo de 5 Caracteres</>
                ) : (
                  ''
                )}
              </span>
              <span> {fields.description.length}/250 </span>
            </C.SpanArea>
          </C.InputsDiv>
          {/* <C.SwitchDiv>
            <input
              className='switch'
              type='checkbox'
              onChange={toggleChecked}
            />
            <span> {isPublic ? 'Pública' : 'Privada'} </span>
          </C.SwitchDiv> */}
          <C.Button
            onClick={ev => {
              handleFormSubmit(ev);
            }}
          >
            Criar
          </C.Button>
        </form>
      </C.ModalDiv>
    );
  }
};
