import styled from 'styled-components';

export const Container = styled.section`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25vh;
  flex: 1;
  flex-direction: column;
  padding-left: 6rem;
  padding-right: 6rem;
  text-align: center;
  font-family: 'Montserrat';

  h1 {
    font-size: 26px;
    margin-bottom: 1rem;
  }

  h3 {
    font-size: 20px;
  }

  @media (max-width: 480px) {
    padding-left: 5rem;
    padding-right: 5rem;
    h1 {
      font-size: 16px;
    }

    h3 {
      font-size: 14px;
    }
  }
`;

export const Button = styled.button`
  margin-top: 3rem;
  margin-bottom: 3rem;
  background-color: #ff00b7;
  border: 2px solid #ff00b7;
  width: 15rem;
  height: 3rem;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  transition: all ease 0.5s;
  font-family: 'Montserrat';

  &:hover {
    background-color: #000;
  }

  @media (max-width: 480px) {
    height: 3rem;
  }
`;
