import React, { createContext, useState } from 'react';

export const GalleryModalContext = createContext();

export const GalleryModalProvider = ({ children }) => {
  const [openGalleryModal, setOpenGalleryModal] = useState(false);
  const [item, setItem] = useState();

  return (
    <GalleryModalContext.Provider
      value={{
        ...openGalleryModal,
        setOpenGalleryModal,
        openGalleryModal,
        item,
        setItem,
      }}
    >
      {children}
    </GalleryModalContext.Provider>
  );
};
