import styled from 'styled-components';

export const Footer = styled.footer`
  padding: 3rem 6rem 0 6rem;
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 920px) {
    padding: 0;
  }

  @media (max-width: 620px) {
    gap: 0;
    margin-top: 3rem;
  }
`;
export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  ul {
    list-style: none;
    display: flex;
    gap: 120px;
  }

  li {
    margin-bottom: 25px;
    font-size: 24px;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  a {
    text-decoration: none;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img {
    margin-right: 15px;
  }

  .iconDuvidas {
    width: 40px;
    height: 39px;
  }

  .iconContact {
    width: 22px;
    height: 51px;
  }

  .iconDownload {
    width: 39;
    height: 39;
  }

  @media (max-width: 1100px) {
    ul {
      gap: 80px;
    }
  }

  @media (max-width: 950px) {
    flex-direction: column;
    padding: 0 10%;
    justify-content: center;
    align-items: center;
    margin: 0;

    ul {
      width: 100%;
      flex-wrap: wrap;
      justify-content: space-between;
      flex-direction: column;
      gap: 15px;
    }

    li {
      font-size: 24px;
      margin: 0px 0px;
    }

    img {
      height: 25px;
      margin-right: 8px;
    }

    .iconContact {
      height: 25px;
      width: 11px;
    }
  }
  @media (max-width: 620px) {
    ul {
      flex-direction: row;
      justify-content: center;
    }

    li {
      font-size: 22px;
    }

    img {
      height: 18.5px;
      margin-right: 4px;
    }

    a {
      gap: 8px;
      font-size: 0.9rem;
    }
    .iconContact {
      height: 12.5px;
      width: 5.5px;
    }
  }

  @media (max-width: 420px) {
    padding: 0;

    li {
      font-size: 1.2rem;
    }
  }
`;

export const Bar = styled.div`
  img {
    width: calc(100vw - 12rem);
  }

  @media (max-width: 950px) {
    padding: 0 5%;
    margin: 15px 0;
    img {
      width: 100%;
    }
  }
  @media (max-width: 500px) {
    padding: 0 25px;
    margin-bottom: 15px;
    img {
      width: 100%;
    }
  }
`;

export const InfoFooter = styled.div`
  display: flex;
  align-items: center;
  margin: 2rem 12rem 0 12rem;

  @media (max-width: 1350px) and (min-width: 960px) {
    margin: 2rem 8rem 0 8rem;
  }
  img.logo {
    width: 183px;
    height: 75px;
    margin-top: -50px;
  }

  p {
    text-align: center;
    margin: auto;
    font-size: 15px;
    font-weight: 400;
    padding: 2rem;
  }

  .lojas-apps {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  button {
    width: 165.63px;
    height: 51px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    background-color: #f0f5f7;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px;
    margin: 0px 20px 0px 0;
  }

  img.apple {
    width: auto;
    height: 25.91px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  img.google {
    width: auto;
    height: 51px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  @media (max-width: 1350px) {
    p {
      font-size: 13px;
      padding: 1rem;
    }
  }

  @media (max-width: 1020px) {
    padding: 0 10%;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    img.logo {
      width: 120px;
      height: 50px;
      margin-top: 40px;
    }

    button {
      width: 186px;
      height: 57px;
      margin: 10px 0 10px 0;
    }

    img.apple {
      height: 29px;
    }

    img.google {
      height: 57px;
    }

    .lojas-apps {
      gap: 10px;
      justify-content: space-between;
    }

    p {
      font-size: 15px;
      margin: 10px 0;
    }
  }

  @media (max-width: 620px) {
    padding: 0 10%;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img.logo {
      width: inherit;
      height: inherit;
    }

    button {
      width: 125px;
      height: 38px;
      margin: 10px 0 10px 0;
    }

    img.apple {
      height: 19.37px;
    }

    img.google {
      height: 38px;
    }

    .lojas-apps {
      margin-bottom: 10px;
      flex-direction: row;
      justify-content: space-between;
    }

    p {
      font-size: 13px;
      margin: 10px 0;
    }
  }
`;

export const SocialFooter = styled.div`
  display: flex;
  margin: 20px 0px;
  h1 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    font-size: 24px;
    font-weight: bold;
  }

  @media (max-width: 950px) {
    h1 {
      font-size: 20px;
      font-weight: 500;
    }
  }
  @media (max-width: 500px) {
    h1 {
      font-size: 18px;
      font-weight: 500;
    }
  }
`;

export const Social = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  width: 100%;
  gap: 50px;
  margin-bottom: 40px;

  a {
    width: 45px;
    display: flex;
    justify-content: space-around;
  }

  .instagram {
    width: 40px;
  }

  .twitter {
    width: 45px;
  }

  @media (max-width: 950px) {
    a {
      margin: 0px 0px;
      width: 30px;
    }
    img {
      width: auto;
      height: 45px;
      display: flex;
      flex: 1;
      flex-direction: column;
      margin: 0;
    }

    .facebook {
      height: 30px;
    }

    .twitter {
      height: 36px;
    }
  }
  @media (max-width: 620px) {
    padding: 0;
    margin-bottom: 30px;
    gap: 30px;

    a {
      margin: 0px 0px;
      width: 15px;
    }

    img {
      width: auto;
      height: 25px;
      display: flex;
      flex: 1;
      flex-direction: column;
      margin: 0;
    }

    .twitter {
      height: 30px;
    }

    .instagram {
      height: 33px;
    }
  }
`;
