import DOMPurify from 'dompurify';
import { z } from 'zod';

export const signin = z
  .object({
    email: z
      .string()
      .email('Digite um email válido')
      .transform(field => DOMPurify.sanitize(field)),
    password: z
      .string()
      .min(8, 'A senha deve possuir no  8 caracteres')
      .transform(field => DOMPurify.sanitize(field)),
  })
  .required();
