import React from 'react';
import { AuthProvider } from './Auth';
import { AvaliationModalProvider } from './AvaliationModal/AvaliationModalContext';
import { CreateGalleryModalProvider } from './CreateGalleryModal/CreateGalleryModalContext';
import { GalleryModalProvider } from './GalleryModal/GalleryModalContext';
import { ModalProvider } from './Modal/ModalContext';
import { UserInterestsProvider } from './UserInterests';

export const Contexts = ({ children }) => {
  return (
    <ModalProvider>
      <AuthProvider>
        <AvaliationModalProvider>
          <GalleryModalProvider>
            <CreateGalleryModalProvider>
              <UserInterestsProvider>{children}</UserInterestsProvider>
            </CreateGalleryModalProvider>
          </GalleryModalProvider>
        </AvaliationModalProvider>
      </AuthProvider>
    </ModalProvider>
  );
};
