import styled from 'styled-components';

export const ModalDiv = styled.div`
  width: 892px;
  background-color: #121212;
  border-radius: 24px;
  position: fixed;
  top: 9rem;
  left: calc(35vw - 200px);
  flex-direction: column;
  z-index: 99999;

  @media (max-width: 912px) {
    width: 90%;
    left: calc(35vw - 30%);
  }

  @media (max-width: 820px) {
    left: 1.9rem;
  }
  @media (max-width: 700px) {
    left: 1.5rem;
  }

  @media (max-width: 375px) {
    top: 4rem;
  }
`;

export const CongratulationModal = styled.div`
  width: 892px;
  background-color: #121212;
  border-radius: 24px;
  position: fixed;
  top: 9rem;
  left: calc(35vw - 200px);
  flex-direction: column;
  z-index: 99999;

  @media (max-width: 912px) {
    width: 90%;
    left: calc(35vw - 30%);
  }

  @media (max-width: 820px) {
    left: 1.9rem;
  }
  @media (max-width: 700px) {
    left: 1.5rem;
  }

  @media (max-width: 375px) {
    top: 4rem;
  }
`;

export const CloseBtn = styled.button`
  width: 30px;
  height: 35px;
  font-size: 26px;
  font-style: normal;
  color: #ff00b7;
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  margin: 10px;
  border: none;
  cursor: pointer;
`;

export const AddOnGalleryDiv = styled.div`
  height: 267px;
  justify-content: center;
  width: 481px;
  display: flex;
  align-items: center;
  margin: 50px auto 20px auto;

  @media (max-width: 600px) {
    width: inherit;
  }
`;

export const TextOnAddDiv = styled.div`
  height: 51px;
  width: 481px;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  margin: 10px auto 30px;
  color: #ffffff;

  @media (max-width: 600px) {
    width: inherit;
  }
`;

export const InputsDiv = styled.div`
  width: 450px;
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  gap: 0px;

  input {
    height: 50px;
    border-radius: 15px;
    background: transparent;
    border: 1px solid antiquewhite;
    padding: 10px 15px;
    color: #ffffff;
    outline: none;
  }

  input::placeholder {
    padding: 10px 5px;
  }

  textarea {
    height: 150px;
    border-radius: 15px;
    background: transparent;
    border: 1px solid antiquewhite;
    color: #ffffff;
    padding: 20px 0px 0px 20px;
    outline: none;
  }

  textarea::placeholder {
    padding: 40px 0px;
  }

  @media (max-width: 620px) {
    width: inherit;

    input {
      width: 90%;
      margin: 0 auto;
    }

    textarea {
      width: 90%;
      margin: 0 auto;
    }
  }
`;

export const SpanArea = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 10px 20px 10px;

  @media (max-width: 280px) {
    font-size: 13px;
  }
`;

export const SwitchDiv = styled.div`
  display: flex;
  width: 150px;
  margin: 20px auto;
  align-items: center;
  justify-content: space-around;

  .switch {
    position: relative;
    height: 1.2rem;
    width: 2.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    border-radius: 9999px;
    background-color: rgba(100, 116, 139, 0.377);
    transition: all 0.3s ease;
  }

  .switch:checked {
    background-color: rgba(255, 0, 183, 0.3);
  }

  .switch::before {
    position: absolute;
    content: '';
    left: calc(1.5rem - 1.6rem);
    top: calc(1.5rem - 1.6rem);
    display: block;
    height: 1.3rem;
    width: 1.3rem;
    cursor: pointer;
    border: 1px solid rgba(100, 116, 139, 0.527);
    border-radius: 9999px;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 3px 10px rgba(100, 116, 139, 0.327);
    transition: all 0.3s ease;
  }

  .switch:checked:before {
    transform: translateX(100%);
    background-color: #ff00b7;
  }
`;

export const Button = styled.button`
  background: #ff00b7;
  border-radius: 14px;
  height: 40px;
  width: 125px;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  font-weight: 800;
  margin: 20px auto 40px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 375px) {
    margin: 10px auto 20px;
  }
`;
