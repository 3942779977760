import { useQuery } from '@tanstack/react-query';
import React, { createContext, useEffect, useState } from 'react';
import {
  getMyAlreadyWatched,
  getMyFavorites,
  getMyGallerys,
  getMyWantWatch,
} from '../../services/Requests';
import { useAuth } from '../Auth/useAuth';

export const UserInterestsContext = createContext({});

export const UserInterestsProvider = ({ children }) => {
  const { isLoggedIn } = useAuth();
  const [dataFavorites, setDataFavorites] = useState();
  const [dataWantWatch, setDataWantWatch] = useState();
  const [dataWatched, setDataWatched] = useState();
  const [dataGalleries, setDataGalleries] = useState();

  const { data: favorites = [], refetch: favoriteRefetch } = useQuery({
    queryKey: ['favorite-list'],
    queryFn: getMyFavorites,
    enabled: isLoggedIn,
  });

  const { data: wantWatch = [], refetch: watchListRefetch } = useQuery({
    queryKey: ['watch-list'],
    queryFn: getMyWantWatch,
    enabled: isLoggedIn,
  });

  const { data: watched = [], refetch: alreadyWatchRefetch } = useQuery({
    queryKey: ['watched'],
    queryFn: getMyAlreadyWatched,
    enabled: isLoggedIn,
  });

  const { data: galleries = [], refetch: galleryRefetch } = useQuery({
    queryKey: ['galleries'],
    queryFn: getMyGallerys,
    enabled: isLoggedIn,
  });

  useEffect(() => {
    if (favorites && favorites?.items) {
      setDataFavorites(favorites);
    }
  }, [favorites, setDataFavorites]);

  useEffect(() => {
    if (wantWatch && wantWatch?.items) {
      setDataWantWatch(wantWatch);
    }
  }, [wantWatch, setDataWantWatch]);

  useEffect(() => {
    if (watched && watched?.items) {
      setDataWatched(watched);
    }
  }, [watched, setDataWatched]);

  useEffect(() => {
    if (galleries && galleries?.items) {
      setDataGalleries(galleries);
    }
  }, [galleries, setDataGalleries]);

  return (
    <UserInterestsContext.Provider
      value={{
        favorites,
        wantWatch,
        watched,
        galleries,
        favoriteRefetch,
        watchListRefetch,
        alreadyWatchRefetch,
        galleryRefetch,
        dataFavorites,
        setDataFavorites,
        dataWantWatch,
        setDataWantWatch,
        dataWatched,
        setDataWatched,
        dataGalleries,
        setDataGalleries,
      }}
    >
      {children}
    </UserInterestsContext.Provider>
  );
};
