import { create } from 'zustand';

export const useFilterStore = create(set => ({
  state: {
    RELEASE_DATE_RANGE: {
      minValue: 1900,
      maxValue: new Date().getFullYear(),
      value: new Date().getFullYear(),
      visibleYear: true,
    },
    IMDB_NOTE: {
      minimumRated: 5,
      above: false,
      below: false,
      equal: false,
    },
    SORTER: {
      ascending: false,
      descending: false,
    },
    SHOW_RELEASE_DATE: false,
    SHOW_IMDB_NOTE: false,
    SHOW_SORTER: false,
  },
  trigger: false,
  actions: {
    setImdbNote: payload =>
      set(state => ({
        state: {
          ...state.state,
          IMDB_NOTE: {
            ...state.state.IMDB_NOTE,
            minimumRated: payload,
          },
        },
      })),
    setReleaseDateRange: payload =>
      set(state => ({
        state: {
          ...state.state,
          RELEASE_DATE_RANGE: {
            ...state.state.RELEASE_DATE_RANGE,
            value: payload,
          },
        },
      })),
    setAscending: payload =>
      set(state => ({
        state: {
          ...state.state,
          SORTER: {
            ...state.state.SORTER,
            ascending: payload,
            descending: false,
          },
        },
      })),
    setDescending: payload =>
      set(state => ({
        state: {
          ...state.state,
          SORTER: {
            ...state.state.SORTER,
            descending: payload,
            ascending: false,
          },
        },
      })),
    setAbove: payload =>
      set(state => ({
        state: {
          ...state.state,
          IMDB_NOTE: {
            ...state.state.IMDB_NOTE,
            above: payload,
            below: false,
            equal: false,
          },
        },
      })),
    setBelow: payload =>
      set(state => ({
        state: {
          ...state.state,
          IMDB_NOTE: {
            ...state.state.IMDB_NOTE,
            below: payload,
            above: false,
            equal: false,
          },
        },
      })),
    setEqual: payload =>
      set(state => ({
        state: {
          ...state.state,
          IMDB_NOTE: {
            ...state.state.IMDB_NOTE,
            equal: payload,
            below: false,
            above: false,
          },
        },
      })),
    setVisibleYear: payload =>
      set(state => ({
        state: {
          ...state.state,
          RELEASE_DATE_RANGE: {
            ...state.state.RELEASE_DATE_RANGE,
            visibleYear: payload,
          },
        },
      })),
    setShowReleaseDate: payload =>
      set(state => ({
        state: {
          ...state.state,
          SHOW_RELEASE_DATE: payload,
        },
      })),
    setShowImdbNote: payload =>
      set(state => ({
        state: {
          ...state.state,
          SHOW_IMDB_NOTE: payload,
        },
      })),
    setShowSorter: payload =>
      set(state => ({
        state: {
          ...state.state,
          SHOW_SORTER: payload,
        },
      })),
    reset: () => {
      const initialState = {
        RELEASE_DATE_RANGE: {
          minValue: 1900,
          maxValue: new Date().getFullYear(),
          value: new Date().getFullYear(),
          visibleYear: true,
        },
        IMDB_NOTE: {
          minimumRated: 5,
          above: false,
          below: false,
          equal: false,
        },
        SORTER: {
          ascending: false,
          descending: false,
        },
        SHOW_RELEASE_DATE: false,
        SHOW_IMDB_NOTE: false,
        SHOW_SORTER: false,
      };
      set({ state: initialState, trigger: false });
    },
  },
  setTrigger: payload => set({ trigger: payload }),
}));
