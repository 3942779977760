export const bannerSizeHome = [
  'fluid',
  [600, 220],
  [1920, 90],
  [1200, 90],
  [970, 250],
  [125, 125],
  [930, 180],
  [120, 240],
  [1, 1],
  [234, 60],
  [250, 250],
  [1024, 768],
  [88, 31],
  [728, 90],
  [240, 400],
  [300, 75],
  [320, 100],
  [300, 50],
  [180, 150],
  [960, 90],
  [468, 60],
  [320, 50],
  [200, 200],
  [200, 446],
  [980, 90],
  [160, 600],
  [120, 90],
  [480, 320],
  [750, 300],
  [220, 90],
  [320, 480],
  [120, 60],
  [216, 36],
  [168, 28],
  [240, 133],
  [300, 600],
  [120, 600],
  [292, 30],
  [300, 1050],
  [768, 1024],
  [300, 100],
  [580, 400],
  [216, 54],
  [168, 42],
  [750, 200],
  [950, 90],
  [250, 360],
  [300, 31],
  [970, 66],
  [120, 30],
  [980, 120],
  [120, 20],
  [970, 90],
  [300, 250],
  [336, 280],
  [750, 100],
];

export const bannerSizeCard = ['fluid', [220, 335], [169, 216]];
