import { useQuery } from '@tanstack/react-query';
import React, { createContext, useEffect, useState } from 'react';
import {
  Logout,
  callbackSignInFacebook,
  callbackSignInGoogle,
  deleteAccount,
  fetchUserInfo,
  signIn,
  signInWithAppleAccount,
} from '../../services/Requests';
import { useOpenSignIn } from '../Modal/useOpenSignIn';
import {
  getUserLocalStorage,
  removeFromStorage,
  setUserLocalStorage,
} from './utils';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { openSignIn, setOpenSignIn } = useOpenSignIn();

  useEffect(() => {
    const user = getUserLocalStorage();

    if (user) {
      setUser(user);
      setIsLoggedIn(true);
    }
  }, [user, isLoggedIn]);

  const authenticate = async (email, password) => {
    const response = await signIn(email, password);
    const payload = { token: response.access_token };

    if (payload.token) {
      setUser(payload);
      setUserLocalStorage(payload);

      return true;
    }
    return false;
  };

  const callbackAuthGoogle = async (code, state, host) => {
    const response = await callbackSignInGoogle(code, state, host);
    const payload = { token: response.data.access_token };

    if (payload.token) {
      setUser(payload);
      setUserLocalStorage(payload);
      return true;
    }

    return false;
  };

  const callbackAuthFacebook = async (code, state) => {
    const response = await callbackSignInFacebook(code, state);
    const payload = { token: response.data.access_token };

    if (payload.token) {
      setUser(payload);
      setUserLocalStorage(payload);
      return true;
    }

    return false;
  };

  const callbackAuthApple = async data => {
    const obj = {
      access_token: data.authorization.id_token,
      provider: 'apple',
    };
    const response = await signInWithAppleAccount(obj);

    const payload = { token: response.access_token };

    if (payload.token) {
      setUser(payload);
      setUserLocalStorage(payload);
      setOpenSignIn(!openSignIn);
      return true;
    }
  };

  const logout = async () => {
    setUser(null);
    removeFromStorage();
    await Logout();
    setIsLoggedIn(false);
  };

  const removeAccount = async () => {
    const response = await deleteAccount();
    return response;
  };

  const { data: userInfo = [] } = useQuery({
    queryKey: ['user-data'],
    queryFn: async () => {
      if (isLoggedIn) {
        const data = await fetchUserInfo();
        return data;
      } else {
        return;
      }
    },
    enabled: isLoggedIn,
  });

  return (
    <AuthContext.Provider
      value={{
        ...user,
        authenticate,
        callbackAuthGoogle,
        callbackAuthFacebook,
        callbackAuthApple,
        logout,
        isLoggedIn,
        userInfo,
        removeAccount,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
