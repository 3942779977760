import styled from 'styled-components';

export const Container = styled.section`
  background-color: #121212;
  width: 400px;
  height: 421px;
  top: calc(50vh - 210.5px);
  left: calc(50vw - 200px);
  position: fixed;
  z-index: 999;
  border-radius: 24px;
  background-image: url('assets/images/logo.png');
  background-repeat: no-repeat;
  background-position: 50% 30px;
  display: ${props => props.display};
  flex-direction: column;
  font-family: 'Montserrat';

  p {
    font-size: 16px;
    color: #ff00b7;
    font-weight: 700;
    text-align: center;
    margin-top: 120px;
  }

  span {
    color: #fff;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 172px;
    height: 46px;
    color: #fff;
    background-color: #ff00b7;
    border: none;
    cursor: pointer;
    border-radius: 14px;
    font-size: 14px;
    font-weight: 800;
    margin: 0 auto;
  }
`;

export const Image = styled.div`
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  height: 50px;
  margin: 50px;
  background-position-x: 50%;
`;

export const Background = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
`;
