import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as C from './style.js';

const Rating = ({ onRatingChange, initialRating }) => {
  const [rating, setRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0);
  const [fixedRating, setFixedRating] = useState(initialRating);
  const [getImage, setGetImage] = useState(0);

  const handleMouseOver = (value, event) => {
    const starValueRect = event.target.getBoundingClientRect();
    const clickX = event.clientX - starValueRect.left;
    const isLeftSide = clickX <= starValueRect.width / 2;
    const side = isLeftSide ? 'left' : 'right';

    if (side === 'left') {
      setGetImage(value - 0.5);
    } else {
      setHoverRating(value);
      setGetImage(value);
    }
  };

  //UseEffect para setar o valor do hover das estrelas
  useEffect(() => {
    setGetImage(hoverRating);
  }, [hoverRating]);

  //Setando as estrelas pra 0 quando o mouse sair de cima de alguma delas
  const handleMouseLeave = () => {
    setGetImage(initialRating);
  };

  //Função de onClick. Vai verificar se o lado é direito ou esquerdo. Se for direito, vai setar a avaliação como metade. É responsavel tambem pra remover a estrela se clicar dnv nela
  const handleClick = (value, event) => {
    const starValue = event.target;
    const starValueRect = starValue.getBoundingClientRect();
    const clickX = event.clientX - starValueRect.left;
    const isLeftSide = clickX <= starValueRect.width / 2;
    const side = isLeftSide ? 'left' : 'right';

    if (value === rating || value === fixedRating) {
      setRating(0);
      setFixedRating(0);
      onRatingChange(0);
      setGetImage(0);
    } else {
      if (side === 'left') {
        if (value - 0.5 === rating) {
          setRating(0);
          setFixedRating(0);
          onRatingChange(0);
          setGetImage(0);
        } else {
          setRating(value - 0.5);
          setFixedRating(value - 0.5);
          onRatingChange(value - 0.5);
          setGetImage(value - 0.5);
        }
      } else {
        setRating(value);
        setFixedRating(value);
        onRatingChange(value);
        setGetImage(value);
      }
    }
  };

  //Função para capturar a imagem
  const getStarImage = value => {
    if (value <= getImage) {
      return '/assets/images/starFilled.svg';
    } else if (value - 0.5 <= getImage) {
      return '/assets/images/halfStar.svg';
    }
    return '/assets/images/emptyStar.svg';
  };

  //Função de mostrar a pontuação
  const calculateScore = () => {
    let score = 0;
    if (fixedRating >= 0) {
      score = fixedRating;
    } else if (rating >= 0) {
      score = rating;
    }
    return score;
  };

  useEffect(() => {
    setFixedRating(initialRating);
    setGetImage(initialRating);
  }, [initialRating]);

  return (
    <C.Container>
      <C.StarDiv onMouseLeave={handleMouseLeave}>
        {[1, 2, 3, 4, 5].map(value => (
          <img
            key={uuidv4()}
            src={getStarImage(value)}
            alt={`Rating ${value}`}
            onMouseMoveCapture={event => {
              handleMouseOver(value, event);
            }}
            onClick={event => {
              handleClick(value, event);
            }}
            className={'star-value'}
          />
        ))}
      </C.StarDiv>

      {fixedRating >= 0 && (
        <C.RatingDiv>
          <C.Score>{calculateScore()}</C.Score>
        </C.RatingDiv>
      )}
    </C.Container>
  );
};

export default Rating;
