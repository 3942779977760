import React, { createContext, useState } from 'react';

export const AvaliationModalContext = createContext();

export const AvaliationModalProvider = ({ children }) => {
  const [openAvaliationModal, setOpenAvaliationModal] = useState(false);
  const [item, setItem] = useState();

  return (
    <AvaliationModalContext.Provider
      value={{
        ...openAvaliationModal,
        setOpenAvaliationModal,
        openAvaliationModal,
        item,
        setItem,
      }}
    >
      {children}
    </AvaliationModalContext.Provider>
  );
};
