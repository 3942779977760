import React from 'react';
import { Routers } from './Routes';

import { Contexts } from './contexts';
import ScrollToTop from './utils/ScrollToTop';
import GlobalStyle from './GlobalStyle';

const App = () => {
  return (
    <Contexts>
      <GlobalStyle />
      <ScrollToTop />
      <Routers />
    </Contexts>
  );
};

export default App;
