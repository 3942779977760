import { create } from 'zustand';

const useSearchInput = create(set => ({
  size: false,
  focus: false,
  value: '',
  select: 0,
  submit: false,
  responseData: [],
  setValue: value => set(() => ({ value: value })),
  setFocus: () => set(() => ({ focus: true })),
  removeFocus: () => set(() => ({ focus: false })),
  setSelect: position => set(() => ({ select: position })),
  setSubmit: () => set(() => ({ submit: true })),
  setSize: size => set(() => ({ size: size })),
  setRemoveSubmit: () => set(() => ({ submit: false })),
}));

export default useSearchInput;
