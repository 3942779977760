import { useMutation } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toastErr, toastSuc } from '../../../config/toastComponent';
import { registerQRCode } from '../../../services/Requests';

const initalState = {
  first_input: '',
  second_input: '',
  third_input: '',
  fourth_input: '',
  fifth_input: '',
  sixth_input: '',
};

export const useQRCode = () => {
  const [qrCode, setQrCode] = useState(initalState);
  const location = useLocation();
  const getLastParam = location.pathname.split('/').at(-1);
  const navigate = useNavigate();

  const inputs = [
    {
      id: 1,
      name: 'first_input',
      value: qrCode.first_input,
    },
    {
      id: 2,
      name: 'second_input',
      value: qrCode.second_input,
    },
    {
      id: 3,
      name: 'third_input',
      value: qrCode.third_input,
    },
    {
      id: 4,
      name: 'fourth_input',
      value: qrCode.fourth_input,
    },
    {
      id: 5,
      name: 'fifth_input',
      value: qrCode.fifth_input,
    },
    {
      id: 6,
      name: 'sixth_input',
      value: qrCode.sixth_input,
    },
  ];

  useEffect(() => {
    if (getLastParam.length === 6) {
      setQrCode(prev => ({
        ...prev,
        first_input: getLastParam[0],
        second_input: getLastParam[1],
        third_input: getLastParam[2],
        fourth_input: getLastParam[3],
        fifth_input: getLastParam[4],
        sixth_input: getLastParam[5],
      }));
    }
  }, [getLastParam]);

  const handleChange = (e, currentIndex) => {
    const { name, value } = e.target;

    setQrCode({
      ...qrCode,
      [name]: value,
    });

    const nextEmptyIndex = inputs.findIndex(
      (input, index) => index > currentIndex && qrCode[input.name] === '',
    );

    if (nextEmptyIndex !== -1) {
      const nextInput = document.getElementById(inputs[nextEmptyIndex].id);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };

  const { mutateAsync, error } = useMutation(registerQRCode, {
    onSuccess: data => {
      if (data) {
        toastSuc('Estamos logando...');
        navigate('/');
      } else {
        toastErr('Código Inválido');
      }
    },
  });

  const submitQRCode = async e => {
    e.preventDefault();

    const values = Object.entries(qrCode).map(([, value]) => {
      return value.concat();
    });

    const notIsEmpty = values.every(value => value !== '');

    if (notIsEmpty) {
      await mutateAsync(values.join(''));
    } else {
      toastErr('Preencha os campos');
    }
  };

  return {
    qrCode,
    setQrCode,
    submitQRCode,
    inputs,
    handleChange,
    error,
  };
};
