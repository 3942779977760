import styled from 'styled-components';

export const Container = styled.section`
  margin-top: 5rem;
  width: 100%;
  overflow: hidden;
  padding: 0 6rem;
`;

export const Form = styled.form`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 10rem 0;

  @media (max-width: 27.5rem) {
    margin: 3rem 0;
  }
`;

export const Input = styled.input`
  outline: 0;
  width: 80px;
  height: 80px;
  background: #232423;
  margin-right: 1rem;
  color: #fff;
  border: 0;
  border-radius: 8px;
  text-align: center;
  font-weight: 700;
  font-size: 1.5rem;

  @media (max-width: 27.5rem) {
    width: 40px;
    height: 40px;
    font-size: 1rem;
  }
`;

export const Mask = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Button = styled.button`
  margin-top: 1rem;
  padding: 1.2rem;
  max-width: 500px;
  width: 100%;
  background: #ff00b7;
  color: #fff;
  font-size: 1rem;
  font-weight: 700;
  border-color: #ff00b7;
  border-radius: 12px;
  transition: ease-in-out 500ms;

  &:hover {
    background: #000;
    color: #ff00b7;
  }

  @media (max-width: 27.5rem) {
    padding: 0.6rem;
    width: 80%;
  }
`;

export const Error = styled.p`
  visibility: ${props => props.isError};
  position: relative;
  height: 1.5rem;
`;

export const FlashMessage = styled.div`
  height: 1.5rem;
  margin-bottom: 0.5rem;
  color: #ff00b7;

  @media (max-width: 27.5rem) {
    margin-bottom: 1.5rem;
  }
`;
