import { useEffect, useState } from 'react';
import { toastErr, toastSuc } from '../../config/toastComponent';
import { useOpenAvaliationModal } from '../../contexts/AvaliationModal/useOpenAvaliationModal';
import { ratingPost } from '../../services/Requests';
import Rating from '../Commom/StarRating';
import * as C from './styles';

export const AvaliationModal = () => {
  const [score, setScore] = useState(0);
  const modal = useOpenAvaliationModal();
  const item = modal.item;

  const handleScoreChange = newScore => {
    setScore(newScore);
  };

  const close = () => {
    modal.setOpenAvaliationModal(!modal.openAvaliationModal);
  };

  const verifyRating = item.media_type === 'serie' ? 'serie' : 'movie';

  const handleSubmit = async ev => {
    ev.preventDefault();
    await ratingPost(
      item.id || item.media_id,
      item.first_air_date ? 'serie' : verifyRating,
      score * 2,
    )
      .then(() => {
        item.our_rating.user = score * 2;
        toastSuc('Obrigado pela avaliação');
        close();
      })
      .catch(() => {
        toastErr('Tente novamente!');
      });
  };

  useEffect(() => {
    if (item && item.our_rating) setScore(item.our_rating.user / 2);
  }, [item]);

  return (
    <C.ModalDiv
      style={{ display: modal.openAvaliationModal ? 'flex' : 'none' }}
    >
      <C.CloseBtn
        onClick={() => {
          close();
          setScore(item?.our_rating?.user / 2);
        }}
      >
        X
      </C.CloseBtn>
      <C.TittleDiv>
        <h3 style={{ margin: '3rem auto' }}>
          Sua avaliação para <br /> {item ? item.title || item.name : ''}
        </h3>
      </C.TittleDiv>

      <form
        onSubmit={handleSubmit}
        style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
      >
        <Rating onRatingChange={handleScoreChange} initialRating={score} />
        <C.Button>Confirmar avaliação</C.Button>
      </form>
    </C.ModalDiv>
  );
};
