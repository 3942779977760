import { Footer } from '../../../components/Footer';
import { Header } from '../../../components/Header';
import ToastComponent from '../../../config/toastComponent';
import { MyTvComponent } from '../components/MyTvComponent';

export const MyTvScreens = () => {
  return (
    <>
      <Header />
      <ToastComponent />
      <MyTvComponent />
      <Footer />
    </>
  );
};
