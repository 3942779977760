import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin: 0 auto 30px auto;
  width: 80%;
  justify-content: space-evenly;
  flex-direction: column;
`;

export const StarDiv = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;

  img {
    width: 42.47px;
    height: 38px;
  }

  @media (max-width: 410px) {
    img {
      width: 36px;
      height: 32px;
    }
  }

  @media (max-width: 280px) {
    img {
      width: 26px;
      height: 22px;
    }
  }
`;

export const RatingDiv = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`;

export const Score = styled.h3`
  width: 58px;
  height: 47px;
  line-height: 47px;
  font-size: 37px;
  text-align: center;
`;
