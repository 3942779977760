import React from 'react';
import { Route, Routes } from 'react-router';
import { GenresRoute } from '../pages/Genres/constants/GenresEnum';
import MyTvScreens from '../pages/MyTv';
import loadable from '@loadable/component';

const Contact = loadable(() => import('../pages/Contact'));
const Doubts = loadable(() => import('../pages/Doubts'));
const Home = loadable(() => import('../pages/Home'));
const NotFound = loadable(() => import('../pages/NotFound'));
const Platform = loadable(() => import('../pages/Platform'));
const Person = loadable(() => import('../pages/Person'));
const Movie = loadable(() => import('../pages/Movie'));
const Serie = loadable(() => import('../pages/Serie'));
const Settings = loadable(() => import('../pages/Settings'));
const Gallery = loadable(() => import('../pages/Gallery'));
const Profile = loadable(() => import('../pages/Profile'));
const GenericPage = loadable(() => import('../pages/GenericPage'));
const MovieGenres = loadable(() => import('../pages/MovieGenres'));
const SerieGenres = loadable(() => import('../pages/SerieGenres'));
const ProtectedLayer = loadable(() => import('../components/ProtectedLayer'));
const GoogleAuth = loadable(() => import('../components/GoogleAuth'));
const FacebookAuth = loadable(() => import('../components/FacebookAuth'));
const SearchPage = loadable(() => import('../pages/Search'));
const MyGallery = loadable(() => import('../pages/MyGallery'));
const ResetPass = loadable(() => import('../components/ResetPass'));
const Releases = loadable(() => import('../pages/Releases'));
const PlatformView = loadable(() => import('../pages/PlatformView'));
const SearchGallery = loadable(() => import('../pages/SearchGalery'));
const Genres = loadable(() => import('../pages/Genres'));
const Privacy = loadable(() => import('../pages/Privacy'));
const RegisterVerify = loadable(() => import('../components/RegisterVerify'));
const Confirmation = loadable(() => import('../pages/Confirmation'));
const OurLists = loadable(() => import('../pages/OurLists'));
const Lists = loadable(() => import('../pages/Lists'));

export const Routers = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/category/:category_name' element={<GenericPage />} />
      <Route path='/releases' element={<Releases />} />
      <Route path='/platform/:provider_id' element={<Platform />} />
      <Route path='/doubts' element={<Doubts />} />
      <Route path='/contact' element={<Contact />} />
      <Route path='/movie/:movie_id' element={<Movie />} />
      <Route path='/serie/:serie_id' element={<Serie />} />
      <Route path='/person/:person_id' element={<Person />} />
      <Route path='/auth/callback' element={<GoogleAuth />} />
      <Route path='/auth/facebook/callback' element={<FacebookAuth />} />
      <Route path='/platforms' element={<PlatformView />} />
      <Route path={GenresRoute.GENRES} element={<Genres />} />
      <Route
        path='/settings'
        element={
          <ProtectedLayer>
            <Settings />
          </ProtectedLayer>
        }
      />
      <Route
        path='/gallery'
        element={
          <ProtectedLayer>
            <Gallery />
          </ProtectedLayer>
        }
      />
      <Route
        path='/profile'
        element={
          <ProtectedLayer>
            <Profile />
          </ProtectedLayer>
        }
      />
      <Route
        path='/gallery/:gallery_id'
        element={
          <ProtectedLayer>
            <MyGallery />
          </ProtectedLayer>
        }
      />
      <Route
        path='/mytv/:code?'
        element={
          <ProtectedLayer>
            <MyTvScreens />
          </ProtectedLayer>
        }
      />
      <Route path='/movie/genre/:genre_id' element={<MovieGenres />} />
      <Route path='/privacy' element={<Privacy />} />
      <Route path='/serie/genre/:genre_id' element={<SerieGenres />} />
      <Route path='/search' element={<SearchPage />} />
      <Route path='/search-gallery/:galleryId' element={<SearchGallery />} />
      <Route path='/reset-password' element={<ResetPass />} />
      <Route path='/register-verify' element={<RegisterVerify />} />
      <Route path='/account-confirmation' element={<Confirmation />} />
      <Route path='/our-lists' element={<OurLists />} />
      <Route path='/our-lists/:saga_id' element={<OurLists />} />
      <Route path='/lists' element={<Lists />} />
      <Route path='*' element={<NotFound />} />
    </Routes>
  );
};
