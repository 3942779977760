import { Unexpected } from '../../../components/Unexpected';
import { useQRCode } from '../hooks/useQRCode';
import * as C from '../styles/styles';

export const MyTvComponent = () => {
  const { inputs, handleChange, submitQRCode, error } = useQRCode();

  if (error) {
    return <Unexpected />;
  }

  return (
    <C.Container>
      <C.Form>
        <C.Mask>
          {inputs.map((input, index) => (
            <C.Input
              key={input.id}
              maxLength={1}
              id={input.id}
              value={input.value}
              name={input.name}
              onChange={e => handleChange(e, index)}
              autoFocus={index === 0}
            />
          ))}
        </C.Mask>

        <C.Button type='submit' onClick={e => submitQRCode(e)}>
          Validar
        </C.Button>
      </C.Form>
    </C.Container>
  );
};
