import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { queryClient } from './config/reactQuery';
import { Hydrate, QueryClientProvider } from '@tanstack/react-query';
import { HelmetProvider } from 'react-helmet-async';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-TC2M2L6Q',
};

TagManager.initialize(tagManagerArgs);

const dehydratedState = window.__REACT_QUERY_STATE;

ReactDOM.hydrateRoot(
  document.getElementById('root'),
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Hydrate state={dehydratedState}>
        <HelmetProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </HelmetProvider>
      </Hydrate>
    </QueryClientProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
