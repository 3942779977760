import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth/useAuth';
import * as C from './styles';

export const UserInfo = () => {
  const queryClient = useQueryClient();
  const { userInfo, logout } = useAuth();
  const [arrow, setArrow] = useState(false);
  const [photo, setPhoto] = useState(
    userInfo?.profile?.photo_url || '/assets/images/user.png',
  );
  const [username, setUserName] = useState(userInfo?.username || '');
  const navigate = useNavigate();

  const handleArrow = () => {
    setArrow(!arrow);
  };

  const handleLogout = async () => {
    await logout();
    navigate('/');
    window.location.reload();
  };

  useEffect(() => {
    if (userInfo?.username) {
      setUserName(userInfo?.username);
    }
    if (userInfo?.profile)
      setPhoto(userInfo?.profile?.photo_url || '/assets/images/user.png');
  }, [userInfo, userInfo?.profile?.photo_url]);

  useEffect(() => {
    queryClient.refetchQueries(['user-data']);
  }, [queryClient]);

  return (
    <>
      <C.Container>
        {!arrow ? (
          <C.UserImage onClick={handleArrow}>
            <img src={photo} alt='user' />
            <C.DownArrow image={'/assets/images/arrow-down.png'} />
          </C.UserImage>
        ) : (
          <C.Menu display={arrow ? 'block' : 'none'}>
            <C.HeaderMenu onClick={handleArrow}>
              <C.UserContent>
                <C.UserName>{username}</C.UserName>
                <C.UserMail>{userInfo?.email}</C.UserMail>
              </C.UserContent>
              <img src={photo} alt='user' />
              <C.ArrowMenu className='arrow-desk' />
            </C.HeaderMenu>
            <C.Options>
              <li>
                <Link to={'/settings'}>
                  <img src='/assets/images/settings.svg' alt='settings' />
                  CONFIGURAÇÕES
                </Link>
              </li>
              <li>
                <Link to={'/profile'}>
                  <img src='/assets/images/avatar.svg' alt='avatar' /> PERFIL
                </Link>
              </li>
              <li>
                <Link to={'/gallery'}>
                  <img src='/assets/images/photo.svg' alt='photo' /> MINHAS
                  LISTAS
                </Link>
              </li>
              <li>
                <Link onClick={() => handleLogout()}>
                  <img src='/assets/images/logout.svg' alt='logout' /> SAIR
                </Link>
              </li>
            </C.Options>
            <C.ArrowMenu className='arrow-mobile' />
          </C.Menu>
        )}
        <C.ModalBackground
          className='teste'
          isActive={arrow}
          onClick={() => setArrow(false)}
        />
      </C.Container>
    </>
  );
};
