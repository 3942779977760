import { Link } from 'react-router-dom';
import * as C from './styles';

const handleClickedUp = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <>
      <C.Footer>
        <C.Container>
          <ul>
            <li>
              <Link to='/doubts'>
                <img
                  className='iconDuvida'
                  src='/assets/images/interrogation.png'
                  alt='doubt'
                />
                Dúvidas
              </Link>
            </li>
            <li>
              <Link to='/privacy'>
                <img
                  className='iconDownload'
                  src='/assets/images/terms.png'
                  alt='download'
                />
                Política de Privacidade
              </Link>
            </li>
          </ul>
        </C.Container>
        <C.Bar>
          <img src='/assets/images/footer-bar.png' alt='' />
        </C.Bar>
        <C.InfoFooter>
          <img
            src='/assets/images/logo-footer.png'
            alt='logo-footer'
            className='logo'
            onClick={handleClickedUp}
            style={{ cursor: 'pointer' }}
          />

          <p>
            ahshow.tv - Todo o conteúdo externo permanece propriedade do <br />
            legítimo proprietário. Copyright &copy; {year}
          </p>
          <div className='lojas-apps'>
            <Link
              to='https://apps.apple.com/br/app/ahshow/id6449369993'
              target='blank'
            >
              <button>
                <img
                  src='/assets/images/appleicon.png'
                  alt='apple'
                  className='apple'
                />
              </button>
            </Link>

            <Link
              to='https://play.google.com/store/apps/details?id=com.ahshow'
              target='blank'
            >
              <button>
                <img
                  src='/assets/images/googleicon.png'
                  alt='google'
                  className='google'
                />
              </button>
            </Link>
          </div>
        </C.InfoFooter>
        <C.SocialFooter>
          <h1>Segue a gente</h1>
        </C.SocialFooter>
        <C.Social>
          <Link
            to='https://www.facebook.com/people/AhShow/61551879721279'
            target='_blank'
          >
            <img
              src='/assets/images/fb.png'
              alt='facebook'
              className='facebook'
            />
          </Link>
          <Link to='https://twitter.com/ahshow_tv' target='_blank'>
            <img
              src='/assets/images/twitter-x.svg'
              alt='twitter'
              className='twitter'
            />
          </Link>
          <Link to='https://www.instagram.com/ahshow.tv/' target='_blank'>
            <img
              src='/assets/images/instagram.svg'
              alt='instagram'
              className='instagram'
            />
          </Link>
        </C.Social>
      </C.Footer>
    </>
  );
};
