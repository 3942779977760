import { useNavigate } from 'react-router-dom';
import { Footer } from '../Footer';
import { Header } from '../Header';
import * as C from './styles';

export const Unexpected = () => {
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <C.Container>
        <h1>Ops! Ocorreu algo inesperado.</h1>
        <h3>
          Desculpe-nos pelo transtorno. Estamos trabalhando para melhorar sua
          navegação.
        </h3>
        <C.Button onClick={() => navigate('/')}>
          Clique aqui para retornar à Home
        </C.Button>
      </C.Container>
      <Footer />
    </>
  );
};
