import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { toastErr } from '../../config/toastComponent';
import { useOpenGalleryModal } from '../../contexts/GalleryModal/useOpenGalleryModal';
import { useUserInterests } from '../../contexts/UserInterests/useUserInterests';
import { addMediaToGallery } from '../../services/Requests';
import * as C from './style';
// import { useOpenCreateGalleryModal } from '../../contexts/CreateGalleryModal/useOpenCreateGalleryModal';

export const GalleryModal = () => {
  const [checkedItems, setCheckedItems] = useState([]);
  const [addOnGallery, setAddOnGallery] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const modal = useOpenGalleryModal();
  // const createModal = useOpenCreateGalleryModal();

  const { galleryRefetch, galleries } = useUserInterests();
  const Gallery = galleries;
  const item = modal?.item;

  const close = () => {
    modal.setOpenGalleryModal(false);
  };

  const navigate = useNavigate();
  const handleOpenContent = e => {
    e.preventDefault();
    e.stopPropagation();
    close();
    navigate('/gallery');
  };

  const handleChecked = index => {
    const newCheckedItems = [...checkedItems];
    newCheckedItems[index] = !newCheckedItems[index];
    setCheckedItems(newCheckedItems);
  };

  const handleGallery = async (media_id, media_type) => {
    const selectedGallery = [];
    for (let index = 0; index < checkedItems.length; index++) {
      const element = checkedItems[index];

      if (element === true) {
        selectedGallery.push(Gallery?.items[index]);
      }
    }

    let hasEvery = selectedGallery.every(item =>
      item.medias.some(media => media.media_id === media_id),
    );

    let hasSome = selectedGallery.some(item =>
      item.medias.some(media => media.media_id === media_id),
    );

    if (hasEvery) {
      toastErr('Este filme já está em sua lista!');
      galleryRefetch();
      return;
    }

    if (hasSome) {
      for (let i = 0; i < selectedGallery.length; i++) {
        const indexToRemove = selectedGallery.findIndex(item =>
          item.medias.some(media => media.media_id === media_id),
        );

        if (indexToRemove !== -1) {
          selectedGallery.splice(indexToRemove, 1);
        }
      }
    }

    for (const item of selectedGallery) {
      try {
        await addMediaToGallery(item.id, media_id, media_type);
        setAddOnGallery(true);
      } catch (error) {
        toastErr('Tente novamente!');
      }

      galleryRefetch();
    }
  };

  useEffect(() => {
    if (Gallery && Gallery?.items !== undefined) {
      const newCheckedItems = Gallery?.items.map(gallery => {
        const hasItem = gallery?.medias?.some(
          media => media?.media_id === item?.id,
        );
        return hasItem;
      });
      setCheckedItems(newCheckedItems);
    }
  }, [Gallery, item, addOnGallery]);

  useEffect(() => {
    const hasTrue = checkedItems.some(element => element === true);
    setIsDisabled(!hasTrue);
  }, [checkedItems]);

  useEffect(() => {
    let galleryIdArr = [];
    Gallery?.items?.map(item => {
      galleryIdArr.push(item.id);
    });
  }, [Gallery]);

  if (addOnGallery === false) {
    return (
      <C.ModalDiv style={{ display: modal.openGalleryModal ? 'flex' : 'none' }}>
        <C.CloseBtn onClick={close}> X </C.CloseBtn>
        <C.TittleDiv>
          <h3 style={{ margin: '3rem auto 1rem auto' }}>
            Adicionar {item ? item.title || item.name : 'Nome não disponivel'}{' '}
            em:
          </h3>
          <h4>Marque a lista que você deseja adicionar</h4>
        </C.TittleDiv>
        <C.GalleryDiv>
          {Gallery.items && Gallery?.items.length > 0 && Gallery ? (
            <C.List>
              {Gallery?.items.map((gallery, index) => (
                <li key={uuidv4()}>
                  <input
                    type='checkbox'
                    name=''
                    id=''
                    checked={checkedItems[index]}
                    onChange={() => handleChecked(index)}
                  />
                  <strong>{gallery.name}</strong>
                  <span>({gallery.total_items}) </span>
                </li>
              ))}
            </C.List>
          ) : (
            <>
              <p>Você ainda não adicionou nenhuma lista</p>
            </>
          )}
        </C.GalleryDiv>
        <C.ButtonsDiv>
          <C.Button
            onClick={() => {
              handleGallery(item.id, item.first_air_date ? 'serie' : 'movie');
            }}
            disabled={isDisabled}
          >
            Adicionar
          </C.Button>
          {/* <C.CreateGalleryBtn
            onClick={() => {
              close();
              createModal.setOpenGalleryModal(true);
            }}
          >
            Criar lista
          </C.CreateGalleryBtn> */}
        </C.ButtonsDiv>

        <div
          style={{
            margin: '20px auto',
            color: '#ffffff',
            opacity: '0.7',
            fontSize: '14px',
            lineHeight: '24px',
            cursor: 'pointer',
            textDecoration: 'underline',
          }}
          onClick={handleOpenContent}
        >
          Ver todas as coleções
        </div>
      </C.ModalDiv>
    );
  } else {
    return (
      <C.ModalDiv style={{ display: modal.openGalleryModal ? 'flex' : 'none' }}>
        <C.CloseBtn
          onClick={() => {
            close();
            setAddOnGallery(false);
            setCheckedItems(Gallery?.items.map(() => false));
          }}
        >
          X
        </C.CloseBtn>
        <C.AddOnGalleryDiv>
          <img src='/assets/images/addResult.png' alt='' />
        </C.AddOnGalleryDiv>
        <C.TextOnAddDiv>
          {item ? item.title || item.name : 'Nome não disponivel'} foi
          adicionado à sua lista.
        </C.TextOnAddDiv>
      </C.ModalDiv>
    );
  }
};
