import styled from 'styled-components';

export const Container = styled.section`
  background-color: #121212;
  width: 400px;
  height: 421px;
  top: calc(50vh - 210.5px);
  left: calc(50vw - 200px);
  position: fixed;
  z-index: 999;
  border-radius: 24px;
  background-image: url('/assets/images/logo.png');
  background-repeat: no-repeat;
  background-position: 50% 30px;
  display: ${props => props.display};
  flex-direction: column;

  p {
    font-size: 16px;
    color: #ff00b7;
    font-weight: 700;
    text-align: center;
  }

  span {
    color: #fff;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 172px;
    height: 46px;
    color: #fff;
    background-color: #ff00b7;
    border: none;
    cursor: pointer;
    border-radius: 14px;
    font-size: 14px;
    font-weight: 800;
    margin: 0 auto;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: #fff !important;
    -webkit-background-clip: text;
  }

  @media (max-width: 500px) {
    width: 80%;
    height: auto;
    left: 10%;
    top: calc(50vh - 250px);
    background-position: center 30px;

    p {
      margin-bottom: 12px;
    }

    button {
      width: 150px;
      height: 40px;
    }
  }
`;

export const Image = styled.div`
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  height: 50px;
  margin: 50px;
  background-position-x: 50%;
`;

export const Info = styled.div``;

export const Title = styled.div`
  h2 {
    margin-top: 120px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
  }
`;

export const Subtitle = styled.div`
  p {
    color: #fff;
    font-weight: 300;
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

export const Form = styled.form`
  input {
    background-color: transparent;
    border: none;
    margin: auto;
    width: 280px;
    display: flex;
    outline: 0;
    color: #fff;
    height: 43px;
  }

  button {
  }
`;

export const FormInput = styled.div`
  width: 352px;
  height: 46px;
  border: none;
  border-radius: 8px;
  margin: auto;
  margin-bottom: 30px;
  background-image: url('/assets/images/email.png');
  background-repeat: no-repeat;
  background-position: 10px 15px;
  background-color: #242323;
  font-family: 'Montserrat';

  input {
    border: none;
    font-family: 'Montserrat';
  }

  @media (max-width: 500px) {
    width: 90%;
    height: 34px;
    background-position: 10px center;

    input {
      width: 200px;
      margin-left: 40px;
      height: 34px;
    }
  }
`;

export const FooterInfo = styled.div`
  p {
    color: #fff;
    margin-top: 20px;
  }

  span {
    color: #ff00b7;
    cursor: pointer;
  }

  @media (max-width: 500px) {
    p {
      margin-bottom: 30px;
    }
  }
`;

export const ErrorMessage = styled.div`
  display: flex;
  margin-top: 5px;
  p {
    font-weight: 400;
    font-size: 12px;
  }
`;

export const ErrorRequest = styled.div``;

export const Background = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
`;
