import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
//import AppleSignin from 'react-apple-signin-auth';
import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/Auth/useAuth';
import { useOpenSignIn } from '../../contexts/Modal/useOpenSignIn';
import { signInWithGoogleAccount } from '../../services/Requests';
import { RecoverPassword } from '../RecoverPassword';
import { SignUp } from '../SignUp';
import { signin } from './signin';
import { Helmet } from 'react-helmet-async';
import { generateRandomString } from '../../helpers/generateRandomString';
import * as C from './styles';

export const SignIn = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: zodResolver(signin),
  });

  const auth = useAuth();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [changeType, setChangeType] = useState(false);
  const [callSignUp, setCallSignUp] = useState(false);
  const [recoverPassword, setRecoverPassword] = useState(false);
  const [credentialsError, setCredentialsError] = useState('');
  const location = useLocation();
  const urlOrigin = window.location.origin;

  const modal = useOpenSignIn();

  const state = generateRandomString(16);

  const nonce = generateRandomString(16);

  const handleForm = async data => {
    try {
      const user = {
        email: data.email,
        password: data.password,
      };

      const hasUser = await auth.authenticate(user.email, user.password);

      if (hasUser) {
        setIsLoggedIn(true);
        modal.setOpenSignIn();
      } else {
        setCredentialsError('Email e/ou senha incorretos');
        return;
      }
    } catch (error) {
      return null;
    }
  };

  const handleGoogleLogin = async e => {
    e.preventDefault();
    const response = await signInWithGoogleAccount(
      'email',
      'profile',
      urlOrigin,
    );

    if (response.status === 200) {
      localStorage.setItem('route', location.pathname);
      window.location.href = response.data.authorization_url;
    }
  };

  /* const handleFacebookLogin = async e => {
    e.preventDefault();
    const response = await signInWithFacebookAccount('email', 'public_profile', urlOrigin);

    if (response.status === 200) {
      localStorage.setItem('route', location.pathname);
      window.location.href = response.data.authorization_url;
    }
  }; */

  const handleRecoverPassword = () => setRecoverPassword(!recoverPassword);

  const handleVisible = () => {
    setChangeType(!changeType);
  };

  if (callSignUp) {
    return <SignUp />;
  }

  if (recoverPassword) {
    return <RecoverPassword />;
  }

  return (
    <>
      <Helmet>
        <meta name='appleid-signin-client-id' content='com.ahshow.app' />
        <meta name='appleid-signin-scope' content='email name' />
        <meta
          name='appleid-signin-redirect-uri'
          content={`${urlOrigin}/auth/apple/callback`}
        />
        <meta name='appleid-signin-state' content={state} />
        <meta name='appleid-signin-nonce' content={nonce} />
        <meta name='appleid-signin-use-popup' content='false' />
      </Helmet>
      <C.Background onClick={() => modal.setOpenSignIn(!modal.openSignIn)} />
      <C.Container
        display={
          isLoggedIn || recoverPassword ? 'none' : callSignUp ? 'none' : 'block'
        }
      >
        <C.Paragraph>Entrar!</C.Paragraph>
        <C.Error>
          {credentialsError && (
            <C.ErrorMessage>{credentialsError}</C.ErrorMessage>
          )}
        </C.Error>

        <C.Form onSubmit={handleSubmit(handleForm)}>
          <C.MaskEmail image={'/assets/images/user-icon.png'}>
            <input
              type='email'
              placeholder='Email'
              name='email'
              {...register('email')}
            />
          </C.MaskEmail>
          <C.ErrorMessage>
            {errors.email?.message && errors.email.message}
          </C.ErrorMessage>
          <C.MaskPassword image={'/assets/images/locked.png'}>
            <input
              type={changeType ? 'text' : 'password'}
              placeholder='Senha'
              name='password'
              {...register('password')}
            />
            <C.Button
              type='button'
              onClick={handleVisible}
              image={
                changeType
                  ? 'assets/images/see.svg'
                  : 'assets/images/notSee.svg'
              }
            ></C.Button>
          </C.MaskPassword>

          <C.ErrorMessage>
            {errors.password?.message && errors.password.message}
          </C.ErrorMessage>
          <C.P>
            Esqueceu sua senha?{' '}
            <span onClick={handleRecoverPassword}> Clique aqui!</span>
          </C.P>
          <C.Submit type='submit'>Entrar</C.Submit>
        </C.Form>
        <p>Ou entre com</p>
        <C.Social>
          <C.SocialButton
            onClick={handleGoogleLogin}
            image={'/assets/images/google-icon.png'}
            background={'#5383EC'}
          >
            Google
          </C.SocialButton>
          {/* <C.SocialButton
            onClick={handleFacebookLogin}
            image={'/assets/images/facebook-icon.png'}
            background={'#3A4F82'}
          >
            Facebook
          </C.SocialButton> */}
          {/* <AppleSignin
            authOptions={{
              clientId: 'com.ahshow.app',
              scope: 'email name',
              redirectURI: `${urlOrigin}/auth/apple/callback`,
              state: state,
              nonce: nonce,
              usePopup: false,
            }}
            onSuccess={async response => auth.callbackAuthApple(response)}
            onError={error => {
              console.log('olha o erro aí', error);
            }}
            render={renderProps => (
              <C.SocialButton
                onClick={renderProps.onClick}
                image={'/assets/images/apple.svg'}
                background={'#FFF'}
                color={'#000'}
              >
                Apple
              </C.SocialButton>
            )}
          /> */}
        </C.Social>
        <C.P>
          Não tem conta?
          <span onClick={() => setCallSignUp(!callSignUp)}> Inscreva-se</span>
        </C.P>
      </C.Container>
    </>
  );
};
