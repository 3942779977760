import styled from 'styled-components';

export const Header = styled.header`
  box-shadow: inset 0 50px 60px black;
  padding: 0 6rem;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 73px;
  z-index: 999;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background-color: ${props =>
    props.isBlack ? 'rgba(18,18,18, 1)' : 'rgba(0,0,0, 0.5)'};
  transition: all ease 0.5s;
  .logo {
    width: 75%;
    margin-top: 5px;
  }

  .content-logo {
    cursor: pointer;
  }

  @media (max-width: 1360px) and (min-width: 950px) {
    padding: 0 2.5rem;
  }

  @media (max-width: 950px) {
    padding: 0px 3% 0 3%;
    height: 62px;
    align-items: center;
  }
  @media (max-width: 500px) {
    padding: 0px 5% 0 5%;
    height: 55px;
    align-items: center;

    .logo {
      width: 80px;
      height: 32.75px;
    }
  }
  @media (max-width: 500px) {
    padding: 0px 3% 0 3%;
    height: 50px;
    align-items: center;

    .logo img {
      width: 65px;
      height: 28px;
    }
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;

  ul {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  li {
    margin: 0 36px;
  }

  a {
    text-decoration: none;
    color: #e9e9e9;
    cursor: pointer;
    font-weight: 700;
    font-size: 14px;
  }

  a:hover {
    color: #fff;
  }
  @media (max-width: 1366px) and (min-width: 950px) {
    padding: 0 4rem;

    ul {
      width: 100%;
      justify-content: space-between;
    }

    li {
      margin: 0;
      display: flex;
      flex: 1;
      flex-wrap: nowrap;
      justify-content: space-around;
      align-items: center;
      padding: 1rem;
    }
  }

  @media (max-width: 950px) {
    display: none;
  }
`;

export const ModalBackground = styled.div`
  display: ${props => (props.isActive ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.65);
`;

export const Button = styled.button`
  cursor: pointer;
  background-color: #f205b3;
  border: none;
  border-radius: 8px;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  width: 100px;
  height: 50px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 70px;
  padding: 10px;
  font-family: 'Montserrat';

  @media (max-width: 950px) {
    margin: 0;
  }

  @media (max-width: 500px) {
    width: 80px;
    height: 32.75px;
    align-items: center;
    border-radius: 6px;
  }

  @media (max-height: 500px) {
    width: 65px;
    height: 30px;
    font-size: 12px;
  }
`;

export const Sponsor = styled.div`
  width: 100%;
  max-height: 70px;
  height: 100%;
  position: relative;
  top: 4.4rem;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 10;
  background-position: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 1463px) {
    max-height: 90px;
    background-position: 50%;
  }

  @media screen and (max-width: 1024px) {
    max-height: 60px;
    background-position: 30%;
    top: 3.8rem;
  }

  @media screen and (max-width: 580px) {
    top: 3.1rem;
    max-height: 50px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 10;
`;

export const UserInfo = styled.div``;
