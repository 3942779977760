import React from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const toastSuc = message => {
  toast.success(message, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 750,
  });
};

export const toastErr = message => {
  toast.error(message, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: 750,
  });
};
export const ToastComponent = () => {
  return (
    <div>
      <ToastContainer />
    </div>
  );
};

export default ToastComponent;
