/* import { useState } from 'react'; */
import { zodResolver } from '@hookform/resolvers/zod';
import DOMPurify from 'dompurify';
import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { useOpenSignIn } from '../../contexts/Modal/useOpenSignIn';
import { forgotPassword } from '../../services/Requests';
import { RecoverPasswordCongratulations } from '../RecoverPasswordCongratulations';
import { SignIn } from '../SignIn';
import * as C from './styles';

const schema = z
  .object({
    email: z
      .string()
      .email('Digite um email válido')
      .transform(field => DOMPurify.sanitize(field)),
  })
  .required();

export const RecoverPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: zodResolver(schema),
  });

  const [isRecoverd, setIsRecoverd] = useState(false);
  const [successResponse, setSuccessResponse] = useState(false);
  const [confirmationEmail, setconfirmationEmail] = useState('');
  const containerRef = useRef(null);
  const modal = useOpenSignIn();

  const handleForm = async data => {
    try {
      const response = await forgotPassword(data.email);
      if (response.status === 202) {
        setconfirmationEmail(data.email);
        setSuccessResponse(true);
        containerRef.current.style.display = 'none';
      }
    } catch (error) {
      console.log(error);
    }
  };

  if (isRecoverd) {
    return <SignIn />;
  }

  if (successResponse) {
    return <RecoverPasswordCongratulations email={confirmationEmail} />;
  }

  return (
    <>
      <C.Background onClick={() => modal.setOpenSignIn(!modal.openSignIn)} />
      <C.Container display={isRecoverd ? 'none' : 'block'} ref={containerRef}>
        <C.Info>
          <C.Title>
            <h2>Recupere sua senha</h2>
          </C.Title>
          <C.Subtitle>
            <p>
              Vamos enviar um e-mail com instruções <br /> para recuperar sua
              senha
            </p>
          </C.Subtitle>
        </C.Info>
        <C.Form onSubmit={handleSubmit(handleForm)}>
          <C.FormInput>
            <input
              type='email'
              name=''
              id=''
              placeholder='Email'
              {...register('email')}
            />
            <C.ErrorMessage>
              {errors.email?.message && <p>{errors.email.message}</p>}
            </C.ErrorMessage>
          </C.FormInput>
          <button type='submit'>Recuperar!</button>
        </C.Form>
        <C.FooterInfo>
          <p>
            Lembrei
            <span onClick={() => setIsRecoverd(!isRecoverd)}>
              {' '}
              Minha Senha!
            </span>
          </p>
        </C.FooterInfo>
      </C.Container>
    </>
  );
};
