import styled from 'styled-components';

export const Container = styled.div`
  width: 120px;
  height: 47px;
  display: flex;
  position: relative;

  @media (max-width: 500px) {
    width: auto;
    margin: 0;
  }
`;

export const UserImage = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;

  img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    cursor: pointer;
  }

  @media (max-width: 500px) {
    img {
      max-width: 31px;
      max-height: 31px;
      margin: 0;
    }
  }
`;

export const DownArrow = styled.div`
  display: flex;
  flex: 1;
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-size: 22px;
  height: 22px;
  width: 22px;
  background-position: 50%;
  cursor: pointer;
  margin-top: 5px;

  @media (max-width: 500px) {
    display: none;
  }
`;

export const Menu = styled.div`
  background-color: #ff00b7;
  width: 333px;
  color: #fff;
  display: ${props => props.display};
  border-radius: 24px;
  transition: all ease-in-out 0.5s;
  position: absolute;
  right: 0;
  padding: 21px 31px;
  transition: all ease 0.5s;
  z-index: 99999;

  img {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    margin-right: 25px;
  }

  .arrow-mobile {
    display: none;
  }

  @media (max-width: 500px) {
    width: 250px;
    padding: 37px 29px 25px 23px;

    img {
      margin-right: 10px;
      max-width: 45px;
      max-height: 45px;
    }

    .arrow-mobile {
      display: flex;
    }
  }
`;

export const ArrowMenu = styled.div`
  background-image: url('/assets/images/up-white-arrow.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 22px;
  height: 45px;
  display: flex;

  @media (max-width: 500px) {
    width: 100%;
    background-size: 22px;
    margin-top: 5px;
    background-position: bottom;
    background-position-x: right;
  }
`;

export const HeaderMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  @media (max-width: 500px) {
    .arrow-desk {
      display: none;
    }

    .arrow-mobile {
      display: flex;
    }
    flex-direction: column-reverse;
    align-items: end;
  }
`;

export const UserContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`;

export const UserName = styled.h2`
  color: #fff;
  font-weight: 700;
  font-size: 21px;

  @media (max-width: 500px) {
    font-size: 16px;
    margin-top: 23px;
  }
`;

export const UserMail = styled.span`
  display: block;
  width: 170px;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @media (max-width: 500px) {
    font-size: 14px;
  }
`;

export const Options = styled.ul`
  text-decoration: none;

  a {
    text-decoration: none;
    color: #fff;
    display: flex;
    align-items: center;
  }

  img {
    width: 17px;
    height: 17px;
    border-radius: 0;
  }

  li {
    list-style: none;
    margin-top: 40px;
    font-size: 16px;
    font-weight: 700;
  }

  @media (max-width: 500px) {
    margin-top: 35px;

    img {
      margin-right: 7px;
    }
  }
`;

export const ModalBackground = styled.div`
  display: ${props => (props.isActive ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;
