import styled from 'styled-components';

export const Container = styled.section`
  background-color: #121212;
  width: 400px;
  top: calc(50vh - 317.5px);
  left: calc(50vw - 200px);
  height: 690px;
  z-index: 999;
  position: fixed;
  border-radius: 24px;
  background-image: url('/assets/images/logo.png');
  background-repeat: no-repeat;
  background-position: 130px 40px;
  display: ${props => props.display};

  p {
    text-align: center;
    margin-bottom: 10px;
  }

  @media (max-width: 500px) {
    width: 80%;
    height: auto;
    left: 10%;
    top: calc(50vh - 300px);
    background-position: center 30px;

    p {
      margin-bottom: 12px;
    }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-text-fill-color: #fff !important;
    -webkit-background-clip: text;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;

  input {
    width: 250px;
    height: 46px;
    margin-left: 40px;
    margin-right: 40px;
    background-color: transparent;
    border-radius: 8px;
    border: none;
    outline: 0;
    color: #fff;
    display: flex;
    font-size: 14px;
    font-weight: 400;
  }

  @media (max-width: 500px) {
    input {
      width: 80%;
      height: 34px;
    }
  }
`;

export const MaskEmail = styled.div`
  width: 352px;
  height: 46px;
  background-color: #242323;
  margin-bottom: 20px;
  border-radius: 8px;
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-position: 10px center;
  font-family: 'Montserrat';

  @media (max-width: 500px) {
    width: 90%;
    height: 34px;
  }
`;

export const MaskPassword = styled.div`
  width: 352px;
  height: 46px;
  background-color: #242323;
  border-radius: 8px;
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-position: 10px center;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 500px) {
    width: 90%;
    height: 34px;
  }
`;

export const Paragraph = styled.p`
  text-align: center;
  margin-top: 100px;
  font-size: 16px;
  font-weight: 500;
`;

export const P = styled.div`
  align-items: center;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;

  span {
    color: #ff00b7;
    cursor: pointer;
  }

  input {
    width: 15px;
    height: 15px;
  }

  @media (max-width: 500px) {
    margin-top: 10px;
  }
`;

export const Submit = styled.button`
  width: 172px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  border: none;
  color: #fff;
  font-weight: 800;
  font-size: 14px;
  background-color: #ff00b7;
  cursor: pointer;
  margin-bottom: 20px;
  margin-top: 20px;
  font-family: 'Montserrat';

  @media (max-width: 500px) {
    width: 150px;
    height: 40px;
    margin-bottom: 12px;
  }
`;

export const Social = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const SocialButton = styled.button`
  width: 172px;
  height: 46px;
  border-radius: 14px;
  border: none;
  cursor: pointer;
  background-color: ${props => props.background};
  color: #fff;
  font-size: 14px;
  font-weight: 800;
  margin-bottom: 15px;
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  background-position: 10px;

  @media (max-width: 500px) {
    width: 150px;
    height: 40px;
    background-size: 25px;
  }
`;

export const Terms = styled.div`
  display: flex;
  margin-left: -20px;

  p {
    margin-left: -20px;
  }

  input {
    width: 15px;
    height: 15px;
    background-color: #ff00b7;
    margin-top: 2px;
  }

  input:checked {
    background-color: #ff00b7;
  }

  span {
    color: #ff00b7;
  }

  @media (max-width: 5000px) {
    align-items: center;
    justify-content: flex-start;
    width: 84%;
    margin: 0 8% 15px 8%;

    input {
      margin: 0;
    }

    p {
      line-height: 15px;
      font-size: 12px;
      margin: 0 0 0 10px;
    }
  }
`;

export const ErrorParagraph = styled.p`
  color: #ff00b7;
  font-size: 12px;
  display: flex;
  line-height: 20px;
  position: absolute;
  top: ${props => props.height}px;
  text-align: left;

  @media (max-width: 500px) {
    padding-left: 8px;
  }
`;

export const ErrorParagraphPassword = styled.span`
  color: #ff00b7;
  font-size: 12px;
  line-height: 20px;
  width: 100%;
`;

export const Background = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
`;

export const ErrorPassword = styled.div`
  width: 352px;
  height: 30px;

  @media (max-width: 500px) {
    width: 90%;
    padding-left: 8px;
  }
`;

export const Button = styled.button`
  background-color: transparent;
  background-image: url(${props => props.image});
  background-repeat: no-repeat;
  cursor: pointer;
  border: none;
  width: 27px;
  height: 19px;
  margin-right: 8px;
`;

export const Info = styled.div`
  width: 340px;
  font-size: 14px;
  position: relative;
  strong {
    font-weight: 700;
    justify-content: flex-end;
  }

  li {
    list-style: none;
    color: ${props => props.color};
  }

  @media (max-width: 500px) {
    padding-left: 1.2rem;
  }
`;

export const List = styled.li`
  list-style: none;
  color: ${props => props.color};

  @media (max-width: 500px) {
    padding-left: 1.2rem;
  }
`;

export const AlignSubmit = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
