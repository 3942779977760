import { useState } from 'react';

export const useValidateSignIn = () => {
  const [checkPass, setCheckPass] = useState({
    specialChars: false,
    minLength: false,
    upperCase: false,
    lowerCase: false,
    number: false,
  });

  const specialChars = /[!@#$%^&*(),.?":{}|<>=+_¬¢£ªº°-]/g;
  const minLength = 8;
  const upperCase = /[A-Z]/g;
  const lowerCase = /[a-z]/g;
  const numbers = /[0-9]/g;

  const validateCharactersPassword = e => {
    if (e.match(specialChars)) {
      setCheckPass(oldPass => ({
        ...oldPass,
        specialChars: true,
      }));
    } else {
      setCheckPass(oldPass => ({
        ...oldPass,
        specialChars: false,
      }));
    }

    if (e.length >= minLength) {
      setCheckPass(oldPass => ({
        ...oldPass,
        minLength: true,
      }));
    } else {
      setCheckPass(oldPass => ({
        ...oldPass,
        minLength: false,
      }));
    }

    if (e.match(upperCase)) {
      setCheckPass(oldPass => ({
        ...oldPass,
        upperCase: true,
      }));
    } else {
      setCheckPass(oldPass => ({
        ...oldPass,
        upperCase: false,
      }));
    }

    if (e.match(lowerCase)) {
      setCheckPass(oldPass => ({
        ...oldPass,
        lowerCase: true,
      }));
    } else {
      setCheckPass(oldPass => ({
        ...oldPass,
        lowerCase: false,
      }));
    }

    if (e.match(numbers)) {
      setCheckPass(oldPass => ({
        ...oldPass,
        number: true,
      }));
    } else {
      setCheckPass(oldPass => ({
        ...oldPass,
        number: false,
      }));
    }
  };

  return {
    validateCharactersPassword,
    checkPass,
    setCheckPass,
  };
};
