import { useState } from 'react';
import { useOpenSignIn } from '../../contexts/Modal/useOpenSignIn';
import { SignIn } from '../SignIn';
import { SignUp } from '../SignUp';
import * as C from './styles';

export const SignUpCongratulations = ({ text }) => {
  const [createUser, setCreateUser] = useState(false);
  const [isError, setIsError] = useState(false);
  const modal = useOpenSignIn();

  const handleClick = () => {
    if (text.status === 201) {
      setCreateUser(true);
      return <SignIn />;
    } else {
      setIsError(true);
      return <SignUp />;
    }
  };

  return (
    <>
      <C.Background onClick={() => modal.setOpenSignIn(!modal.openSignIn)} />
      <C.Container display={createUser || isError ? 'none' : 'block'}>
        {text.status !== 201 ? (
          <p>
            Deu erro! <span>{text.message}</span>
          </p>
        ) : (
          <p>
            Parabéns! <span>{text.message}</span>
          </p>
        )}

        <C.Image
          image={
            text.status !== 201
              ? '/assets/images/fail.png'
              : '/assets/images/cool.png'
          }
        />
        <button
          onClick={() => {
            handleClick();
          }}
        >
          {text.status !== 201 ? 'Tente novamente' : 'Fazer login!'}
        </button>
      </C.Container>
      {createUser && <SignIn />}
      {isError && <SignUp />}
    </>
  );
};
