import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOpenSignIn } from '../../contexts/Modal/useOpenSignIn';
import { SignIn } from '../SignIn';
import * as C from './styles';

export const RecoverPasswordCongratulations = ({ email }) => {
  const containerRef = useRef(null);
  const [recoverPassword, setRecoverPassword] = useState(false);
  const modal = useOpenSignIn();
  const navigate = useNavigate();

  const handleClose = () => {
    containerRef.current.style.display = 'none';
    modal.setOpenSignIn();
    navigate('/');
  };

  if (recoverPassword) {
    return <SignIn />;
  }

  return (
    <>
      <C.Background onClick={() => modal.setOpenSignIn(!modal.openSignIn)} />
      <C.Container
        display={recoverPassword ? 'none' : 'block'}
        ref={containerRef}
      >
        <C.Info>
          <C.Title>
            <h2>Recupere sua senha</h2>
          </C.Title>
          <C.Subtitle>
            <p>
              Um email com instruções foi enviado para <span>{email}</span>
            </p>
          </C.Subtitle>
        </C.Info>
        <C.Image image={'/assets/images/cool.png'} />
        <C.CloseButton>
          <button onClick={handleClose}>Fechar</button>
        </C.CloseButton>
        <C.FooterInfo>
          <p>
            Lembrei{' '}
            <span onClick={() => setRecoverPassword(!recoverPassword)}>
              minha senha
            </span>
          </p>
        </C.FooterInfo>
      </C.Container>
    </>
  );
};
