import DOMPurify from 'dompurify';
import { z } from 'zod';

export const signup = z
  .object({
    email: z
      .string()
      .email('Email inválido')
      .min(8, 'O email precisa ter no mínimo 8 caracteres')
      .transform(field => DOMPurify.sanitize(field)),
    password: z
      .string()
      .min(8, 'A senha deve possuir no  8 caracteres')
      .regex(/[0-9]/, { message: 'A senha deve conter pelo menos um número' })
      .regex(/[A-Z]/, {
        message: 'A senha deve conter no mínimo uma letra maiúscula',
      })
      .regex(/[a-z]/, {
        message: 'A senha deve conter pelo menos uma letra minúscula',
      })
      .regex(/[!@#$%^&*(),.?":{}|<>=+_¬¢£ªº°-]/, {
        message: 'A senha deve conter pelo menos um caracter especial',
      })
      .transform(field => DOMPurify.sanitize(field)),
    username: z
      .string()
      .min(3, 'O nome deve ter no mínimo 3 caracteres')
      .max(15, 'o nome deve ter no máximo 15 caracteres')
      .transform(field => DOMPurify.sanitize(field)),
    name: z
      .string()
      .min(3, 'O nome deve ter no mínimo 3 caracteres')
      .transform(field => DOMPurify.sanitize(field)),
    agreed: z.boolean().refine(field => field === true, {
      message: 'Você deve concordar com os termos',
    }),
  })
  .required();
