import styled from 'styled-components';

export const ModalDiv = styled.div`
  width: 892px;
  height: 500px;
  background-color: #121212;
  border-radius: 24px;
  position: fixed;
  top: 9rem;
  left: calc(35vw - 200px);
  flex-direction: column;
  z-index: 99999;

  @media (max-width: 912px) {
    width: 90%;
    left: calc(35vw - 30%);
    top: calc(15vh);
  }

  @media (max-width: 700px) {
    left: 1.5rem;
  }

  @media (max-width: 375px) {
    top: 5rem;
  }
`;

export const CloseBtn = styled.button`
  width: 30px;
  height: 35px;
  font-size: 26px;
  font-style: normal;
  color: #ff00b7;
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  margin: 10px;
  border: none;
  cursor: pointer;
`;

export const TittleDiv = styled.div`
  text-align: center;
`;

export const GalleryDiv = styled.div`
  width: 250px;
  height: 237px;
  margin: 30px auto 20px auto;
  border: 1px solid rgba(255, 255, 255, 0.38);
  border-radius: 14px;
  display: flex;
  flex-direction: column;

  p {
    font-size: 14px;
    text-align: center;
    display: flex;
    align-items: center;
    height: 80%;
    justify-content: center;
  }
`;

export const CheckboxDiv = styled.div`
  input[type='checkbox'] {
    appearance: none;
    width: 19px;
    height: 19px;
    border: 1px solid #fbf3f3;
    border-radius: 4px;
    margin: 0px 20px 0px 10px;
    position: relative;
  }

  input[type='checkbox']:checked::before {
    content: '';
    display: block;
    position: absolute;
    top: 40%;
    left: 50%;
    width: 3px;
    height: 10px;
    border: solid #ffffff;
    border-width: 0 3.5px 3.5px 0;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  input[type='checkbox']:checked {
    border: 1px solid white;
    background-color: #ff00b7;
  }
`;

export const Button = styled.button`
  background: #ff00b7;
  border-radius: 14px;
  height: 40px;
  width: 125px;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  font-weight: 800;
  margin: 0px auto;
  border: none;
  cursor: pointer;

  :disabled {
    background: gray;
    cursor: inherit;
  }
`;

export const CreateGalleryBtn = styled.button`
  background: #ff00b7;
  border-radius: 14px;
  height: 40px;
  width: 125px;
  color: #ffffff;
  text-align: center;
  font-size: 16px;
  font-weight: 800;
  margin: 0px auto;
  border: none;
  cursor: pointer;
`;

export const AddOnGalleryDiv = styled.div`
  height: 267px;
  justify-content: center;
  width: 481px;
  display: flex;
  align-items: center;
  margin: 50px auto 20px auto;

  @media (max-width: 620px) {
    width: inherit;
  }
`;

export const TextOnAddDiv = styled.div`
  height: 51px;
  width: 481px;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
  text-align: center;
  margin: 0 auto;
  color: #ffffff;

  @media (max-width: 620px) {
    width: inherit;
  }
`;

export const GalleryLenghtParagraph = styled.p`
  padding-left: 5px;
`;

export const List = styled.ul`
  list-style-type: none;
  padding: 10px;
  overflow: auto;
  scrollbar-height: 20px;

  ::-webkit-scrollbar {
    width: 1px;
  }

  ::-webkit-scrollbar-track {
    border-radius: 20px;
    width: 0.4rem;
    height: 80%;
  }

  ::-webkit-scrollbar-track-piece {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 20px;
    background: #ff00b7;
    scrollbar-width: 20px;
  }

  li {
    display: flex;
    margin: 10px 0 10px 0;
  }

  span {
    min-width: 35px;
    text-align: right;
  }

  strong {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  input[type='checkbox'] {
    appearance: none;
    width: 19px;
    height: 19px;
    border: 1px solid #fbf3f3;
    border-radius: 4px;
    margin-right: 10px;
    position: relative;
  }

  input[type='checkbox']:checked::before {
    content: '';
    display: block;
    position: absolute;
    top: 40%;
    left: 50%;
    width: 3px;
    height: 10px;
    border: solid #ffffff;
    border-width: 0 3.5px 3.5px 0;
    transform: translate(-50%, -50%) rotate(45deg);
  }

  input[type='checkbox']:checked {
    border: none;
    background-color: #ff00b7;
  }
`;

export const ButtonsDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-self: center;
`;
