import axios from 'axios';
import { getUserLocalStorage } from '../contexts/Auth/utils';

export const Api = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
});

Api.interceptors.request.use(
  config => {
    if (typeof window !== 'undefined') {
      const user = getUserLocalStorage();
      if (!user) {
        delete config.headers.Authorization;
        return config;
      }

      config.headers.Authorization = `Bearer ${user}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);
