import { zodResolver } from '@hookform/resolvers/zod';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useOpenSignIn } from '../../contexts/Modal/useOpenSignIn';
import { requestVerifyLogin, signUp } from '../../services/Requests';
import { SignIn } from '../SignIn';
import { SignUpCongratulations } from '../SignUpCongratulations';
import { useValidateSignIn } from './hooks/useValidateSingIn';
import { signup } from './signup';
import * as C from './styles';

export const SignUp = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    resolver: zodResolver(signup),
  });

  const [changeType, setChangeType] = useState(false);
  const [changeModal, setChangeModal] = useState(false);
  const [callSignIn, setCallSignIn] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { checkPass, validateCharactersPassword } = useValidateSignIn();

  const modal = useOpenSignIn();

  const handleForm = async data => {
    const user = {
      email: data.email,
      password: data.password,
      is_active: true,
      is_superuser: false,
      is_verified: false,
      username: data.username,
      profile: {
        name: data.name,
        use_photo_img: false,
        surname: '',
        rgb_color: '',
        bio: '',
        site_url: '',
        twitter_account: '',
        instagram_account: '',
        youtube_url: '',
        tiktok_account: '',
        facebook_account: '',
        photo_url: '',
        cover_url: '',
      },
    };

    const response = await signUp(user);

    if (response.status === 201) {
      const isCreatedEmail = await requestVerifyLogin(data.email);

      if (isCreatedEmail.status === 202) {
        setChangeModal(true);
        setErrorMessage({
          message: `Foi enviado um email de confirmação para ${data.email}`,
          status: response.status,
        });
      }
    } else {
      setChangeModal(true);
      setErrorMessage({
        message: 'Usuário já existe!',
        status: response.response.status,
      });
    }
  };

  if (changeModal) {
    return <SignUpCongratulations text={errorMessage} />;
  }
  if (callSignIn) {
    return <SignIn />;
  }

  return (
    <>
      <C.Background onClick={() => modal.setOpenSignIn(!modal.openSignIn)} />
      <C.Container display={callSignIn ? 'none' : 'block'}>
        <C.Paragraph>Crie sua conta, é rápido!</C.Paragraph>
        <C.Form onSubmit={handleSubmit(handleForm)}>
          <C.MaskEmail image={'/assets/images/user-icon.png'}>
            <input
              type='text'
              placeholder='Nome de usuário'
              name='username'
              {...register('username')}
              maxLength={15}
            />
            {errors.username?.message && (
              <C.ErrorParagraph>{errors.username.message}</C.ErrorParagraph>
            )}
          </C.MaskEmail>
          <C.MaskEmail image={'/assets/images/user-icon.png'}>
            <input
              type='text'
              placeholder='Nome'
              name='name'
              {...register('name')}
              maxLength={50}
            />
            {errors.name?.message && (
              <C.ErrorParagraph>{errors.name.message}</C.ErrorParagraph>
            )}
          </C.MaskEmail>
          <C.MaskEmail image={'/assets/images/mail.png'}>
            <input
              type='email'
              placeholder='Email'
              name='email'
              {...register('email')}
            />
            {errors.email?.message && (
              <C.ErrorParagraph>{errors.email.message}</C.ErrorParagraph>
            )}
          </C.MaskEmail>

          <C.MaskPassword image={'/assets/images/locked.png'}>
            <input
              type={changeType ? 'text' : 'password'}
              placeholder='Senha'
              name='password'
              {...register('password')}
              onChange={e => validateCharactersPassword(e.target.value)}
            />
            <C.Button
              type='button'
              onClick={() => setChangeType(!changeType)}
              image={
                changeType
                  ? 'assets/images/see.svg'
                  : 'assets/images/notSee.svg'
              }
            ></C.Button>
          </C.MaskPassword>
          <C.ErrorPassword>
            {errors.password?.message && (
              <C.ErrorParagraphPassword>
                {errors.password.message}
              </C.ErrorParagraphPassword>
            )}
          </C.ErrorPassword>

          <C.Terms>
            <input type='checkbox' {...register('agreed')} required />

            <p>
              Li e concordo com os <span> Termos de uso</span>
            </p>
          </C.Terms>
          <C.Info>
            <strong>A senha deve conter:</strong>
            <ul>
              <C.List color={checkPass.upperCase ? '#5D5D5D' : '#FFFFFF'}>
                Ao menos uma letra maiúscula;
              </C.List>
              <C.List color={checkPass.lowerCase ? '#5d5d5d' : '#FFFFFF'}>
                Ao menos uma letra mínuscula;
              </C.List>
              <C.List color={checkPass.number ? '#5d5d5d' : '#FFFFFF'}>
                Ao menos um número;
              </C.List>
              <C.List color={checkPass.specialChars ? '#5d5d5d' : '#FFFFFF'}>
                Ao menos um caracter especial;
              </C.List>
              <C.List color={checkPass.minLength ? '#5d5d5d' : '#FFFFFF'}>
                No mímino oito caracteres.
              </C.List>
            </ul>
            <C.AlignSubmit>
              <C.Submit type='submit'>Criar conta</C.Submit>
            </C.AlignSubmit>
          </C.Info>
        </C.Form>
        {/* <p>Ou entre com</p>
        <C.Social>
          <C.SocialButton
            image={'/assets/images/google-icon.png'}
            background={'#5383EC'}
          >
            Google
          </C.SocialButton>
          <C.SocialButton
            image={'/assets/images/facebook-icon.png'}
            background={'#3A4F82'}
          >
            Facebook
          </C.SocialButton>
        </C.Social> */}
        <C.P>
          Já possui uma conta?
          <span onClick={() => setCallSignIn(!callSignIn)}> Entre</span>
        </C.P>
      </C.Container>
    </>
  );
};
