import React, { createContext, useState } from 'react';

export const CreateGalleryModalContext = createContext();

export const CreateGalleryModalProvider = ({ children }) => {
  const [openGalleryModal, setOpenGalleryModal] = useState(false);

  return (
    <CreateGalleryModalContext.Provider
      value={{
        ...openGalleryModal,
        setOpenGalleryModal,
        openGalleryModal,
      }}
    >
      {children}
    </CreateGalleryModalContext.Provider>
  );
};
